import './projects.css'

function Project({titre, indesc,image,access,c}){

    return(
        <div className={c}>
                <div className="p-container">
                    <img src={image} alt={access} />

                    <div className="text">
                        <h4 className="titre">{titre}</h4>
                        <p className="description">{indesc}</p>
                    </div>
                </div>
        </div>
    );
}

export default Project;