import './App.css';
import logo from './img/logo-MIHG.png';
import image1 from './img/image001.png'
import image2 from './img/image002.png'
import image3 from './img/image003.png'
import image4 from './img/image004.png'
import image5 from './img/image005.jpeg'
import image6 from './img/image006.jpeg'
import image7 from './img/image007.jpeg'
import image8 from './img/image008.jpeg'
import image9 from './img/image009.jpeg'
import image10 from './img/image010.jpeg'

import Project from './projects';


function App() {
  return (
    <div className="App">

      <main className="container">

        <img className='logo' src={logo} alt="logo"/>

        <h1 className="Titre">Site en cours de construction.</h1>
        <h3>Consultez les projets que nous avons déjà réalisés :</h3>
        

        <div className='project_container'>
  
        <Project
          c="project d2"
          titre="Presse PROMECAM"
          image={image1}
          access="photo d'une machine"
          indesc="Défaut dans le pliage,
          Déréglage de la machine."
          />

          <Project
          c="project d3"
          titre="Poinçonneuse"
          image={image2}
          access="photo d'une machine"
          indesc="Défaut du contacteur de fermeture de porte
          Et défaut du Contrôleur de Phases"
          />
          
          <Project
          c="project d4"
          titre="Groupe Hydraulique"
          image={image3}
          access="photo d'une machine"
          indesc="Perte de pression donc inutilisable pour les machines outils"
          />

          <Project
          c="project d5"
          titre="Scie Automatique à ruban"
          image={image4}
          access="photo d'une machine"
          indesc="Maintenance préventive"
          />

          <Project
          c="project d5"
          titre="MOULURIERE Coroprofil"
          image={image5}
          access="photo d'une machine"
          indesc="Remplacent de laxe de Translation"
          />

          <Project
          c="project d6"
          titre="Compresseur HS"
          image={image6}
          access="photo d'une machine"
          indesc="Changement condensateur compresseur pour fauteuil de dentiste"
          />

          <Project
          c="project d7"
          titre="Corroyeuses Moulurières automatiques superset nt"
          image={image7}
          access="photo d'une machine"
          indesc="Maintenance préventive"
          />

          <Project
          c="project d8"
          titre="Groupe aspiration copeaux"
          image={image8}
          access="photo d'une machine"
          indesc="problème relais puissance"
          />

          <Project
          c="project d9"
          titre="Mortaiseuse de 1980"
          image={image10}
          access="photo d'une machine"
          indesc="Problème sur la carte électronique. Probleme résolu, fonctionne tres bien"
          />

          <Project
          c="project d10"
          titre="Cisaille Guillotine"
          image={image9}
          access="photo d'une machine"
          indesc="Problème de déplacement de butée. Fonctionne très bien après notre passage"
          />

        </div>

        <div className="bg">
          <div className="bg-rond r1"></div>
          <div className="bg-rond r2"></div>
        </div>
      </main>
      




    </div>
  );
}

export default App;
